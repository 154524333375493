
import { defineComponent } from 'vue';
import BaseToolbar from '@/components/base/BaseToolbar.vue';
import BaseContent from '@/components/base/BaseContent.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import { IonPage, IonContent, IonHeader, IonButton } from '@ionic/vue';
import { RouteName } from '@/router/route-const';
import { useLogin } from '@/hooks/useLogin';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Login',
  components: {
    BaseToolbar,
    BaseContent,
    BaseInput,
    IonPage,
    IonContent,
    IonHeader,
    IonButton,
  },
  setup() {
    const router = useRouter();
    const { form, disabled, isLoading, errors, submit } = useLogin();

    const login = () => {
      submit().then(() => {
        if (!errors.value) {
          router.push({ name: RouteName.HOME });
        }
      });
    };

    return {
      form,
      disabled,
      isLoading,

      login,
    };
  },
});
