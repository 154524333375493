import { ref } from 'vue';
import useStore from '@/store';
import { ActionTypes } from '@/store/modules/auth/actions';

export const useLogin = () => {
  const store = useStore();

  const form = ref({
    email: '',
    password: '',
  });
  const errors = ref();
  const disabled = ref<boolean>(false);
  const isLoading = ref<boolean>(false);

  const submit = async () => {
    errors.value = undefined;
    disabled.value = true;
    isLoading.value = true;
    try {
      await store.dispatch(ActionTypes.login, form.value);
    } catch (err) {
      errors.value = err.response.data.message || err.response.data.errors;
    }
    disabled.value = false;
    isLoading.value = false;
  };

  return {
    form,
    errors,
    disabled,
    isLoading,

    submit,
  };
};
