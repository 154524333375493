<template>
  <ion-page>
    <ion-header>
      <BaseToolbar :page-title="$t('views.login')" :show-footer="false" />
    </ion-header>
    <ion-content>
      <BaseContent class="login">
        <BaseInput
          name="email"
          type="email"
          input-mode="email"
          :placeholder="$t('login.email')"
          v-model="form.email"
        />
        <BaseInput
          name="password"
          type="password"
          :placeholder="$t('login.password')"
          v-model="form.password"
        />

        <ion-button
          color="primary"
          expand="block"
          @click="login"
          v-text="$t('global.confirm')"
        />
      </BaseContent>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseToolbar from '@/components/base/BaseToolbar.vue';
import BaseContent from '@/components/base/BaseContent.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import { IonPage, IonContent, IonHeader, IonButton } from '@ionic/vue';
import { RouteName } from '@/router/route-const';
import { useLogin } from '@/hooks/useLogin';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Login',
  components: {
    BaseToolbar,
    BaseContent,
    BaseInput,
    IonPage,
    IonContent,
    IonHeader,
    IonButton,
  },
  setup() {
    const router = useRouter();
    const { form, disabled, isLoading, errors, submit } = useLogin();

    const login = () => {
      submit().then(() => {
        if (!errors.value) {
          router.push({ name: RouteName.HOME });
        }
      });
    };

    return {
      form,
      disabled,
      isLoading,

      login,
    };
  },
});
</script>

<style lang="scss" scoped>
.login {
}
</style>
